.Home-layout {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
}

.Homeprofile-notification {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0px 20px 0px;
}

.Homeprofile {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  flex-wrap: wrap;
}

.Homenotification {
  display: flex;
  justify-content: flex-end;
}

.Logo-large {
  width: 50%;
  height: 80px;
}

.Home-Cards {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  gap: 12px;
  justify-content: center; /* centers horizontally */
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

.auth-Message {
  color: var(--red-500, #ff5c5c);
}
