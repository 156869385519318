.AddChargerCard-Component {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;

  color: var(--gray-400, #85898f);
  text-align: center;

  /* base/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.AddChargerCard-Component > div {
  width: 100px;
  height: 145px;
  border-radius: 15px;
  border: 1px solid var(--gray-50, #e7e7e9);
  background: var(--secondary-100, #fff);

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.AddChargerCard-Component > div > img {
  width: 100%;
  object-fit: cover;
}

.AddChargerCard-Component-Full {
  color: var(--gray-900, #141d2f);
  text-align: center;

  /* base/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
