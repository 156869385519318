.Charger-layout {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 60px 20px;
  width: 100%;
  height: 100vh; /* Full viewport height */
  position: relative;
}

.HeaderBack {
  position: absolute;
  top: 0;
  width: 100%;
}

.Charger-Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.Charger-title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.Charger-input-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Charger-error {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

.Charger-input-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.Charger-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 150px;
}

.ChargerCard-Component {
  width: 160px;
  border-radius: 10px;
  border: 1px solid var(--gray-50, #e7e7e9);
}

.ChargerCard-Component > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
